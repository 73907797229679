import * as React from "react"
import '../css/main.css'
import '../css/bootstrap.min.css'
import '../css/animate.compat.css'
import '../css/boxicons.min.css'
import '../css/icofont.min.css'
import '../css/owl.carousel.min.css'

import '../css/docStyle.css'

import Head from '../components/head'
import HeaderComponent from "../components/header.js"
import useScript from "../components/useScript"
import FooterComponent from "../components/footer"

// markup
const PrivacyPage = () => {
  
    useScript('static/js/jquery.min.js')
    useScript('static/js/bootstrap.bundle.js')
    useScript('static/js/jquery.easing.min.js')
    useScript('static/js/venobox.min.js')
    useScript('static/js/jquery.waypoints.min.js')
    useScript('static/js/counterup.min.js')
    useScript('static/js/owl.carousel.min.js')
    useScript('static/js/isotope.pkgd.min.js')
    useScript('static/js/aos.js')
    useScript('static/js/main.js')

  return (
    <>
      <Head title={'Terms of Service'} />
      <header id="header" className="fixed-top">
        <HeaderComponent />
      </header>
      <main id='main'>
        <section className="breadcrumbs">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                <h2>Terms of Service</h2>
                <ol>
                    <li><a href="/">Home</a></li>
                    <li>Terms of Service</li>
                </ol>
                </div>
            </div>
        </section>
        <section className="about" data-aos="fade-up">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 pt-4 pt-lg-0 text-justify maintext">
            <h3>Terms of Kvotum Database User and Assignment Agreement</h3>
            <ol type='1' style={{paddingLeft: '2em'}}>
              <li>
                <b>INTRODUCTION AND DEFINITIONS</b>
                <ol>
                  <li>
                    Kvotum secondary loan marketplace is made available to you by OÜ Kvotum, registry code 16089383, registered address Männimäe/1, 74626 Pudisoo küla, Estonia (“Kvotum” or “we”) through the website http://kvotum.com/ (“Website”).
                  </li>
                  <li>
                    Each capitalised term shall have the meaning assigned to it herein, unless otherwise stipulated in the Terms of Use:
                    <table className="table table-sm mt-3">
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Account
                        </th>
                        <td>
                          a profile and account created for the User automatically upon the completion of the registration process and which can be logged in with the User specific credentials to access the Account’s functions, such as the Database and information about the concluded, Assignment Agreements and any transactions made.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Acquisition Application
                        </th>
                        <td>
                          the User’s application for acquiring the chosen Claim from the Database.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Affiliated Company 
                        </th>
                        <td>
                          any legal person that, directly or indirectly (through one or more intermediaries), controls, is controlled by, or is under common control with Kvotum or has signed a cooperation agreement for the processing of payments or information related to execution of operations by Kvotum.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Assignee
                        </th>
                        <td>
                          a User who on the basis of an Assignment Agreement has purchased a Claim against a Borrower.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Assignment Agreement
                        </th>
                        <td>
                          an agreement concluded between an Assignee and Kvotum as described in Section 8.5 for governing an assignment of a Claim to the Assignee for the Claim Price. The main specifics of the Claim subject to the Assignment Agreement are made available to the User prior to the acquisition via the Database and are accessible after the acquisition under your profile. These details form a part of the Assignment Agreement. 
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Borrower 
                        </th>
                        <td>
                          a legal person who is a registered user of the Platform and has concluded a Loan Agreement with an Original Lender. 
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Claim 
                        </th>
                        <td>
                          the Original Lender’s monetary claim against the Borrower resulting from the Loan Agreement, including the Loan principal and Interest described in the Assignment Agreement and any ancillary and other claims arising from the Loan Agreement. The amount and composition of the Claim is stipulated in the Assignment Agreement and the Loan Agreement. The Claim is subject to assignment from Kvotum to the Assignee pursuant to the Assignment Agreement. 
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Claim Price 
                        </th>
                        <td>
                          the amount paid by the Assignee for the Claim to be assigned from Kvotum to the Assignee as agreed upon in the Assignment Agreement.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Database
                        </th>
                        <td>
                          a function of the Account for providing secondary loan marketplace located at the Website and managed by Kvotum for displaying Claims inserted by the Platform and which are available for acquiring. 
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          EEA 
                        </th>
                        <td>
                          the European Economic Area.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          EU
                        </th>
                        <td>
                          the European Union.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Interest
                        </th>
                        <td>
                          the remuneration paid by the Borrower for the use of the Loan in the amount stipulated in the Loan Agreement. 
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Kvotum’s Account
                        </th>
                        <td>
                          any Kvotum’s current account opened with licensed credit institution, payment institution or electronic money institution determined in the Database. 
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Loan
                        </th>
                        <td>
                          any Kvotum’s current account opened with licensed credit institution, payment institution or electronic money institution determined in the Database. 
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Loan Agreement 
                        </th>
                        <td>
                          a loan or other similar debt financing agreement concluded between the Original Lender and the Borrower, on the basis of which the Original Lender grants to the Borrower funds (the Loan) and the Borrower is obliged to repay the Loan and the Interest for the use of the Loan in accordance with the Loan Agreement which repayment is facilitated on the Platform. 
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Original Lender
                        </th>
                        <td>
                          a natural or a legal person that is a registered user of the Platform and granted a Loan to the Borrower in accordance with the Loan Agreement.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Parties
                        </th>
                        <td>
                          Kvotum and the User / the Assignee.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          PEP
                        </th>
                        <td>
                          politically exposed person means a natural person who is or who has been entrusted with prominent public functions and includes the following: heads of state, heads of government, ministers and deputy or assistant ministers; members of parliament or of similar legislative bodies; members of the governing bodies of political parties; members of supreme courts, of constitutional courts or of other high-level judicial bodies, the decisions of which are not subject to further appeal, except in exceptional circumstances; members of courts of auditors or of the boards of central banks; ambassadors, chargés d'affaires and high-ranking officers in the armed forces; members of the administrative, management or supervisory bodies of State-owned enterprises; directors, deputy directors and members of the board or equivalent function of an international organisation; and its family members or person known to be close associate.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Platform
                        </th>
                        <td>
                          third party platform(s), which is authorised by the Original Lenders to display the Loans given by them to the Borrowers on the Database for acquiring the Claims by the Users via the Database. Platform is responsible for facilitating the servicing of the Loans and the acquired Claims for the Users.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Price List
                        </th>
                        <td>
                          a list of prices applicable as specifically indicated on the Website to functionalities and services provided by Kvotum or its Affiliated Companies.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Privacy Notice
                        </th>
                        <td>
                          an informative description on the basis and purposes of Kvotum for processing the personal data of the User’s as a natural person or a legal person’s representative, available <a href="privacy.html">here</a>.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Registration Application
                        </th>
                        <td>
                          an application filled in by a legal or natural person via the Website in order to register as a User and conclude the User Agreement.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Right of Use and Exploitation
                        </th>
                        <td>
                          the licence granted under Section 16.4.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          Terms of Use
                        </th>
                        <td>
                          terms and conditions set forth in the Terms of Kvotum Database User and Assignment Agreement, and resulting User Agreement,  Assignment Agreement, made available on the Website and to be complied with by each User, when visiting or registering the Account on the Website and using the Account’s functions. 
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          User
                        </th>
                        <td>
                          a legal or natural person who is visiting the Website or has submitted its Registration Application and has successfully completed the registration and concluded the User Agreement for the use of the Database. 
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          User Agreement
                        </th>
                        <td>
                          an agreement concluded between the User and Kvotum as described in Section 2.3.1 for governing the use of the Account and its functions.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          User’s Account
                        </th>
                        <td>
                          any current account opened with licensed credit institution, payment institution or electronic money institution registered in the EU, the EEA or Switzerland, or a branch of such institution, in the name of the User.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          User Content
                        </th>
                        <td>
                          all contents and data that the User inserts or makes available via the Account or in connection to its use of the Database or the Website.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '25%'}}>
                          User’s Identification Number 
                        </th>
                        <td>
                          a unique identification number, generated in the Database and assigned to the User, intended for identification of the User by Kvotum while using the Database.
                        </td>
                      </tr>
                    </table>
                  </li>
                </ol>
              </li>
              <li>
                <b>REGISTRATON OF THE USER AND CONCLUSION OF THE USER AGREEMENT</b>
                <ol>
                  <li>
                    In order to register as the User, create the Account and start using the Account’s functions, you shall certify that in the moment of registration and during the use of the Account you comply with the following criteria:
                    <ol>
                      <li>
                        in case of being a representative of a legal person (i.e. a legal entity), you have the authority to bind such legal person to the terms of the User Agreement and that you have shared the credentials of the account with other authorised representatives of the legal person; 
                      </li>
                      <li>
                        you are of sufficient legal age and capacity as a natural person or a duly registered legal person;
                      </li>
                      <li>
                        you are a resident of the EU, the EEA or Switzerland;
                      </li>
                      <li>
                        you are not under the control of jurisdiction that explicitly prohibits the use of the Website or the Account’s functions;
                      </li>
                      <li>
                        in acquiring the Claim, the User will not use funds that directly or indirectly have been received as the result of criminal offence or are related to the financing of terrorism or an attempt of such activities;
                      </li>
                      <li>
                        all transactions on the Website will be performed on behalf of the User as the true beneficial owner;
                      </li>
                      <li>
                        there is no insolvency, bankruptcy and/or liquidation process started regarding the User.
                      </li>
                    </ol>
                  </li>
                  <li>
                    You acknowledge, that Kvotum does not offer any financial services, is not subject to financial supervision and does not conduct its activities under any activity licences required pursuant to financial regulations. Your decisions made in reliance on the Database or your interpretations of the information displayed on the Website or the Database are your own and for which you have full responsibility at your sole risk.
                  </li>
                  <li>
                    The following steps are necessary to register as the User and create the Account: 
                    <ol>
                      <li>
                        Fill in the Registration Application on the Website. Prior to submitting the Registration Application, you will be given the opportunity to read the Terms of Use and the Privacy Notice. You can retrieve the documents from the named links and take notice of them. Prior to submitting your Registration Application, you must confirm that you accept these Terms of Use and have acquainted yourself with the Privacy Notice. By submitting the Registration Application, you declare your binding acceptance of the offer to enter into the User Agreement with Kvotum to access and use the Account and its functions on the basis of these Terms of Use.  Kvotum has the right to refuse from concluding the User Agreement without specifying the reason. After submitting the Registration Application and confirming your e-mail, the User Agreement is concluded, and the registration is completed as of the moment you receive the registration confirmation. 
                      </li>
                      <li>
                        Simultaneously with the completion of the registration process, the Account shall be automatically created, and the User’s Identification Number shall be assigned.
                      </li>
                      <li>
                        You can terminate the registration process at any time and/or suspend the process and resume it at a later point. You can check for any errors in the information that you have entered and, if necessary, correct these by changing your input. 
                      </li>
                      <li>
                        The User accesses the Account’s full functions only after the completion of user identification pursuant to Section 3.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Kvotum will store the text of the User Agreement after the User Agreement has been concluded and will make the respective text available to the User via the Account in a downloadable format. Kvotum will store the circumstances relating to the conclusion of the User Agreement and will thus be able to assess at which time the User agreed to which version of the terms.
                  </li>
                </ol>
              </li>
              <li>
                <b>IDENTIFICATION OF THE USER</b>
                <ol>
                  <li>
                    Kvotum shall identify the User after registration on the Website and before the initial acquisition of a Claim via the dedicated Account function KYC by filling in the special KYC form and uploading the ID documents. Kvotum identifies the User remotely in compliance with the terms and conditions of Kvotum’s internal control system.
                  </li>
                  <li>
                    For the identification process, Kvotum requests and the User provides information and uploads the copies of following documents using a special interface in the Account:
                    <ol>
                      <li>
                        In case the User is a natural person:
                        <ol>
                          <li>
                            copy of passport or equivalent personal identification document issued in the EU, the EEA or Switzerland or document valid for travel issued in the EU, the EEA or Switzerland with a clearly visible first name, last name, personal code (or an equivalent identification number issued by the User’s country of origin), citizenship and/or country of birth, and photo of the User;
                          </li>
                          <li>
                            country of residence
                          </li>
                          <li>
                            taxpayer’s certificate and/or taxpayer’s number, if applicable;
                          </li>
                          <li>
                            information on PEP status.
                          </li>
                        </ol>
                      </li>
                      <li>
                        In case the User is a legal person:
                        <ol>
                          <li>
                            certificate of incorporation or certificate about registration;
                          </li>
                          <li>
                            certificate of directors and secretary, register of directors or other document, confirming the appointment of directors (or management board members);
                          </li>
                          <li>
                            certificate of shareholders or register of members;
                          </li>
                          <li>
                            information on beneficial owners, including documents stipulated in Section 3.2.1;
                          </li>
                          <li>
                            if a beneficial owner or any person in the chain of ownership until a beneficial owner is a legal person – all documents indicated in Sections 3.2.2 to 3.2.2.4;
                          </li>
                          <li>
                            Power of Attorney if the representative is not the director;
                          </li>
                          <li>
                            regarding the representative – one of the documents indicated in Section 1;
                          </li>
                          <li>
                            information on PEP status of the User’s director and/or beneficial owner.  
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    For the sake of complete identification of the User, Kvotum may contact the User and require submitting additional documents and/or information certifying the User’s identity at any time at its own discretion, freely determine additional requirements for the identification of the User, as well as supplement the process of identification. In addition to aforesaid, if Kvotum has any suspicions regarding the User's identity, Kvotum has the right to request the User to submit its photo (selfie) with passport or other equivalent personal identification document, which the User shall upload by using special interface in the Account, or offer to contact Kvotum by using video chat.
                  </li>
                </ol>
              </li>
              <li>
                <b>PERMITTED USE OF THE ACCOUNT</b>
                <ol>
                  <li>
                    The purpose and permitted use of the Account and its functions, is to provide the User with tools to conveniently navigate secondary loan marketplace as the Database and enable acquiring the Claims as described in these Terms of Use. Any use for other purposes or particular misuse of the Account, is not permitted. The User agrees not to use the Account in particular in order to: 
                    <ol>
                      <li>
                        upload, post, email, transmit or otherwise make available any content that is unlawful, harmful, threatening, abusive, fraudulent, harassing, insulting, defamatory, vulgar, obscene, libellous, invasive of another's privacy, hateful or racist, that glorifies violence, is pornographic, unethical or otherwise prohibited or objectionable;
                      </li>
                      <li>
                        impersonate any natural or legal person, or falsely state or otherwise misrepresent the User’s affiliation with a natural or legal person;
                      </li>
                      <li>
                        transmit or otherwise make available any content that the User does not have a right to make available, that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
                      </li>
                      <li>
                        engage in any activity that attempts to reverse engineer, disassemble, decompile, hack or extract any proprietary software used to maintain the Website, the Database, the Account and any related functions;
                      </li>
                      <li>
                        copy, disclose, or distribute any data available on or through the Website, the Account and its functions, in any medium, including without limitation, by any automated or non-automated “database scraping” or any other activity with the purpose of obtaining content or other information for any purpose;
                      </li>
                      <li>
                        interfere with or disrupt the Website, the Database or servers or networks connected to them, including but not limited to hacking or bypassing any measures Kvotum may use to prevent unauthorised access to the Website, the Database and the Account and its functions; and/or
                      </li>
                      <li>
                        violate any applicable national or international rules and laws, as well as rights of third parties.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Failure of observing the limits of purpose and permitted use (including if Kvotum detects any activity, which refers to the User not using the Account for its personal gain only but to managing third party’s interests) is deemed as material breach of these Terms of Use. Kvotum will – without prejudice to any other rights – be entitled to immediately terminate the User Agreement in accordance with Section 17.
                  </li>
                </ol>
              </li>
              <li>
                <b>CONFIDENTIALITY AND SECURITY OF THE USER’S CREDENTIALS AND THE ACCOUNT</b>
                <ol>
                  <li>
                    The User acknowledges that the access to the Account is personal to the User, and the User is obliged not to provide any other person with access to the Account using the User’s e-mail, password, or other security information (except for in case the User is a legal person and the credentials are shared in accordance with Section 2.1.1 above). 
                  </li>
                  <li>
                    The password chosen by the User shall be confidential. The User is responsible for maintaining the confidentiality of the data related to the Account. The User must exercise caution when accessing the Account from a public or shared device so that others are not able to view or record the password or the User’s other credentials. If the User’s password has become known to a third party:
                    <ol>
                      <li>
                        the User shall change the password in its profile settings;
                      </li>
                      <li>
                        if the User has no possibility to act in accordance with Section 5.2.1, the User shall notify Kvotum immediately, but not later than such information has become known to the User via e-mail or contact page on the Website;
                      </li>
                      <li>
                        immediately after receiving of such information, Kvotum shall block log-in to the Account  with the User’s credentials until the User submits an application via e-mail for the assignment of a new password and the User has given a clear order to unblock the User’s log-in to the Account with the User’s credentials. 
                      </li>
                    </ol>
                  </li>
                  <li>
                    The User accepts responsibility for all activities that occur under the Account or from the User’s device in relation to the Account. The User acknowledges that, to the extent permitted by laws, Kvotum shall not be liable, directly or indirectly, for any damage or loss caused or alleged to be caused by, or in connection with the unauthorised use of the User’s credentials and access to the Account and its functions thereof, arising from the User’s failure to maintain the confidentiality, if Kvotum has performed its obligation to apply reasonable industry-standard security measures. 
                  </li>
                  <li>
                    If the User enters an incorrect password 5 (five) times in a row upon Account log-in, the access to the Account with the User’s credentials shall be blocked until the User submits an application via e-mail for the assignment of a new password and the User has given a clear order to unblock the User’s log-in to the Account with the User’s credentials. 
                  </li>
                </ol>
              </li>
              <li>
                <b>SUSPENSION OF THE ACCOUNT</b>
                <ol>
                  <li>
                    Kvotum has the right to implement changes to the Account and its functions to the extent such changes do not have any material adverse effect on the functionality of the Account. 
                  </li>
                  <li>
                    Until all the circumstances have been clarified and, if necessary, know your client procedures have been carried out, Kvotum may suspend the Account and/or its functions entirely or partly, and without any liability to the User, if:
                    <ol>
                      <li>
                        Kvotum has suspicions regarding the performance of illegal transactions, including the relation of the User to the funds that directly or indirectly have been received as the result of criminal offence, money laundering or are related to the financing of terrorism or an attempt of such activities;
                      </li>
                      <li>
                        the User has violated these Terms of Use and the User has not remedied the breach without delay after having been notified by Kvotum or uses the Account in violation of any applicable laws and regulations;
                      </li>
                      <li>
                        the User has provided false or inaccurate information regarding itself to Kvotum and the User refuses to provide the required clarifications within the time requested;
                      </li>
                      <li>
                        Kvotum has suspicions regarding unauthorized access to the Account or the User’s password has become known or might have become known to third parties;
                      </li>
                      <li>
                        it is necessary for repairs, maintenance, or other similar actions, including security updates, in which case Kvotum endeavours to notify the User of the interruption in advance to the extent reasonably possible.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Actions and inactions described in Sections 6.2.1 - 6.2.3 are considered as material breach of these Terms of Use. In case the circumstances described in Section 6.2.1 occur, Kvotum will inform law enforcement or respective authorities in accordance with applicable laws.
                  </li>
                  <li>
                    Kvotum endeavours to notify the User of any interruptions as far in advance as reasonably possible or, if advance notification is not possible due to the urgency of the reasons requiring interruption, without undue delay. 
                  </li>
                  <li>
                    Kvotum is not liable to the User or any third party for any damages, losses or expenses occurring due to Kvotum acting in accordance with Section 6.2.
                  </li>
                </ol>
              </li>
              <li>
                <b>CREDITING TO KVOTUM’S ACCOUNT</b>
                <ol>
                  <li>
                    For acquiring Claims through the Database by “transfer to bank account” method, the User shall transfer the funds, which it wishes to use for acquiring a particular Claim available on the Database to the Kvotum’s Account. In the payment details, the User specifies the User’s Identification Number. If the User has not specified the User’s Identification Number, Kvotum shall have the right to deem such payment not received until any further identification has taken place.  
                  </li>
                  <li>
                    If the User makes a payment in another currency, including any cryptocurrency, Kvotum shall have the right to convert the received amount into Euro according to the currency exchange rate of the institution on which account the funds have been transferred. All expenses related to the conversion of funds shall be covered from the User’s funds.
                  </li>
                  <li>
                    For verifying the funds, Kvotum may contact the User and require submitting additional documents and/or information about the origin of funds.
                  </li>
                  <li>
                    The User can use only verified funds for acquiring the Claims available on the Database. In case Kvotum deems the documents and information submitted by the User insufficient, and thus the funds not verified or the acquisition of the Claim fails (as described in Section 8.6), then Kvotum shall without undue delay return the funds to the User’s Account from which the relevant funds were credited. 
                  </li>
                  <li>
                    Upon returning funds to the User’s Account, Kvotum shall have the right to withhold service fees for the processing of payments, including any bank or institution’s fees to be paid for transfers.
                  </li>
                  <li>
                    All transactions performed by the User are reflected in the form of reports in the Account.
                  </li>
                </ol>
              </li>
              <li>
                <b>ACQUIRING OF THE CLAIM AND CONCLUSION OF THE ASSIGNMENT AGREEMENT</b>
                <ol>
                  <li>
                    After the User’s identification in accordance with Section 3 above, the User has the right to acquire the Claims offered on the Database, by complying with the following conditions:
                    <ol>
                      <li>
                        the User has at its own discretion selected the Claim on the Database, which it wishes to purchase;
                      </li>
                      <li>
                        in the Acquisition Application, the User has agreed to the Claim Price, and the transaction fee that is dependent on the method of payment User has specified.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Prior to submitting the Acquisition Application(s), the User has the right to terminate the acquiring process at any time and/or suspend the process and resume it at a later point.
                  </li>
                  <li>
                    After submitting the Acquisition Application(s) by the User, Kvotum shall register and execute the Acquisition Application(s) in chronological order. The timing of registering the Acquisition Application is determined by the system time of the Database. Kvotum shall have the right to unilaterally amend and supplement the order of execution of the Acquisition Application(s) without prior notification of the User.
                  </li>
                  <li>
                    After the Acquisition Application has been submitted and KYC form filled in accordance with Section 3, Kvotum shall prepare the Assignment Agreement and make  it available for the User immediately. The User shall review the correctness of the Assignment Agreement and become acquainted with the Assignment Agreement, and if the foregoing conforms to the will expressed by the User, the User shall confirm the Assignment Agreement, losing the right of withdrawal and that the User acknowledges that the servicing of the acquired Claim is subject to the terms of the Platform and the Loan Agreement and will take place on the Platform on which the User must create an account after the conclusion of the Assignment Agreement. 
                  </li>
                  <li>
                    By confirming the acknowledgement stated in Section 8.4 and the Assignment Agreement, the User declares its binding acceptance of the offer to enter into the Assignment Agreement with Kvotum. The Assignment Agreement is deemed concluded on the moment Kvotum verifies the funds received from the User for acquiring the Claim (covering the Claim Price and any related transaction fees related to the method of payment the User specified in the Acquisition Application). The User shall receive a confirmation of successful conclusion of the Assignment Agreement and acquisition of the Claim. Within two weeks of the Claim acquisition, the User shall receive an email from the Platform with sign up invitation and onboarding instructions. In case he doesn’t, he shall contact us using contact form or sending email on support@kvotum.com.
                  </li>
                  <li>
                    During the funds verification process the Claim is reserved for the User in the Database. If the verification of the funds is not successful or another hindrance occurs in regard to the acquisition of the Claim, the Assignment Agreement shall not be concluded, and the User shall receive a respective notice. Related funds shall be returned in accordance with Section 7.4.
                  </li>
                  <li>
                    In case the User is a natural person, the Assignment Agreement is deemed as distance contract pursuant to the applicable laws. In such a case, the User is not entitled to withdraw from the concluded Assignment Agreement(s) as per the confirmation given pursuant to Section 8.4.
                  </li>
                  <li>
                    The User acknowledges that as a part of performing the Assignment Agreement, Kvotum discloses certain contact details of the User to the Platform for the Platform to be able to connect the User to the acquired Claim and to contact the User with on-boarding instructions to the Platform. Such information is provided to the Platform only in case of successful acquisition of the Claim.
                  </li>
                  <li>
                    Kvotum has the right to suspend carrying out the conclusion of the Assignment Agreement in case any material circumstances occur (including those described in Sections 6.2.1 - 6.2.5) which may cause substantial damage, loss or harm to Kvotum or the User. Kvotum is not liable to the User or any third party for any damages, losses or expenses occurring due to Kvotum acting in accordance with this Section 8.9.
                  </li>
                  <li>
                    The User undertakes not to contact or meet the Borrower in relation to the concluded Assignment Agreement and the Claim, as well as not to visit the Borrower in its address or place of work, not to contact it without the mediation of the Platform, not to demand making of payments, not to initiate court or arbitration court proceedings against the Borrower.
                  </li>
                  <li>
                    Within the context of acquiring the Claim and concluding the Assignment Agreement, the User acknowledges the following: 
                    <ol>
                      <li>
                        the reservation of the Claim described in Section 8.6, does not ensure acquisition of the Claim;
                      </li>
                      <li>
                        the Platform shall be informed of the User having acquired the Claim immediately upon the acquisition. By signing up on the Platform and using its services, the User shall have the opportunity to receive the Borrower’s repayments through the Platform’s intermediation. The Borrower shall not be informed of the User having acquired the Claim;
                      </li>
                      <li>
                        the Borrower shall repay the Claim and pay the Interest and any other Claim related payments in accordance with the conditions of the Loan Agreement. The Loan and the acquired Claim are serviced on the Platform. As of acquiring the Claim, each and every repayment related to the Claim shall be conducted through the Platform. Resolving any issues arising from the acquired Claim, including any enforcement actions and remedies available, depend on the Loan Agreement and the terms of the Platform and may be subject to supplemental agreements;
                      </li>
                      <li>
                        Kvotum gives no guarantees or confirmations on whether or not the Claim is secured with collateral. Kvotum is not the holder of the collateral related to the Claim and assumes no liability arising from collateral of the Claim. In any event, Kvotum shall not transfer and the User has no right to demand the transfer of collateral to the User from Kvotum under these Terms of Use. The foregoing applies regardless of any information about collateral being displayed in the Database in relation to the Claim or not. 
                      </li>
                      <li>
                        the assignment itself and the Assignment Agreement are governed by the laws of the Republic of Estonia and will be settled in Harju County Court (Estonia), regardless of the applicable law and jurisdiction stipulated in the Loan Agreement; 
                      </li>
                      <li>
                        as of the moment the Assignment Agreement is concluded (as described in Section 8.5), the Assignment Agreement is deemed as being performed in its entirety by the Parties, and there shall be no outstanding obligations in regard to the acquired Claim between Kvotum and the User; 
                      </li>
                      <li>
                        in addition to the Assignment Agreement and the initial assignment documents about Kvotum acquiring the Claim, Kvotum has no obligation to provide the User with any other documentation (including the Loan Agreement) in relation to the User’s acquisition of a Claim.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Kvotum shall not assume any liability that may arise from the following situations and occurrences: 
                    <ol>
                      <li>
                        the Borrower’s or the Original Lender’s default or insolvency;
                      </li>
                      <li>
                        issuance of the Loan in accordance with the Loan Agreement and laws applicable to the Loan and the Loan Agreement;
                      </li>
                      <li>
                        permissibility of the assignment of the Claim under the Loan Agreement and the laws applicable to the Loan and the Loan Agreement, including whether the assignment of a Claim to Kvotum is valid and for any consequences and effects on the User arising from it, including in case the assignment of the Claim to the User is invalid; 
                      </li>
                      <li>
                        the User not being able to acquire the Claim, regardless of the stage of the process or the reason thereof (e.g. in case of Section 8.6);
                      </li>
                      <li>
                        the Claim being fulfilled, waived, assigned or pledged to a third party or otherwise encumbered with third party’s rights and any related consequences and effects on the User;
                      </li>
                      <li>
                        the rights and obligations related to the collateral of the Claim (if any);
                      </li>
                      <li>
                        the Borrower not being informed of the assignment to Kvotum or the User and any related consequences and effects on the User;
                      </li>
                      <li>
                        legality of the activities performed on the Platform and whether the Platform has or must have an activity licence; 
                      </li>
                      <li>
                        the Platform’s default, insolvency, down-time or any other circumstances related to the Platform that have an effect on the User.
                      </li>
                    </ol>
                  </li>
                  <li>
                    The  Assignment Agreement, and related transactions, shall be made available to the User on the Account. The User can review  the terms of the Platform as well as the schedule (if any) of repayment of the Claim and Interest as made available on the Platform by clicking on the link of the project name in your profile. At the request of the User submitted to support@kvotum.com, Kvotum provides the User with the initial assignment documents about Kvotum acquiring the Claim.
                  </li>
                </ol>
              </li>
              <li>
                <b>RIGHTS AND OBLIGATIONS OF THE USER</b>
                <ol>
                  <li>
                    <b>The User undertakes:</b>
                    <ol>
                      <li>
                        not to use its Account, the Database and the Website as a whole for carrying out illegal transactions, including fraud and money laundering;
                      </li>
                      <li>
                        to use the Account solely for its personal gain as a true beneficial owner and not in the interests of any third parties;
                      </li>
                      <li>
                        to provide only true information about itself upon registering the Account and using its functions;
                      </li>
                      <li>
                        to assess the risks to occur from using the Account’s functions continuously and responsibly before concluding any transactions;
                      </li>
                      <li>
                        to use only secure means and devices of electronic communication and data transmission;
                      </li>
                      <li>
                        to take all necessary security measures to avoid the access of unauthorised third parties to the Account;
                      </li>
                      <li>
                        immediately, but no later than within 3 (three) business days, to notify Kvotum in writing or via its Account of any changes to the User’s first name, last name, entity name, e-mail address, User’s Account or other information used for the Account;
                      </li>
                      <li>
                        to respond to Kvotum’s requests immediately, including submit any additional information requested by Kvotum to perform its know your client and related procedures;
                      </li>
                      <li>
                        to use the Account only for the performance of actions according to these Terms of Use;
                      </li>
                      <li>
                        in communication with Kvotum to act in a polite manner, observing the norms of behaviour generally accepted by the public.
                      </li>
                    </ol>
                  </li>
                  <li>
                    By accepting these Terms of Use, the User confirms that it is capable to make individual decisions regarding acquiring of the Claim and conclusion of the Assignment Agreement, as well as understands all risks, including the risk of failure to recover the Loan or its part.
                  </li>
                  <li>
                    The User is informed and acknowledges that Kvotum, the Platform and the Original Lender are obliged to keep the confidentiality of the Borrower’s personal data, and thus no or a very limited information on the Borrower shall be disclosed. The User shall not claim any additional information relating to the Borrower from Kvotum, or the Platform or the Original Lender, or submit any claims in this regard.
                  </li>
                </ol>
              </li>
              <li>
                <b>PAYMENT FOR THE USE OF KVOTUM FUNCTIONS</b>
                <ol>
                  <li>
                    All the functions provided by Kvotum are free for the User, if not stated otherwise. The User shall pay to Kvotum fees only for the additional functions provided by Kvotum that specifically state “fee applicable” pursuant to the Price list.
                  </li>
                  <li>
                    Kvotum shall deduct taxes, fees and other payments, which have to be withheld by Kvotum in accordance with applicable laws from the funds paid by the User if applicable. The User bears full liability for all applicable tax payments arising from the User’s transactions through the Database.
                  </li>
                </ol>
              </li>
              <li>
                <b>LIABILITY OF THE PARTIES</b>
                <ol>
                  <li>
                    The User is liable for all damages arising from the non-fulfilment or inappropriate fulfilment of the Terms or Use, in particular (but not limited to):
                    <ol>
                      <li>
                        damages  incurred as the result of unauthorized conduct, if the User has acted illicitly or has failed to fulfil the requirements prescribed in Sections 3.1 and 5.1; 
                      </li>
                      <li>
                        losses, assumed commitments, or other activities carried out via the Account until Kvotum has been warned about situations specified in Section 5.2 and when Kvotum has had sufficient time to block access to the Account;
                      </li>
                      <li>
                        damages and losses incurred due to actions and inactions described in Sections 6.2.1 - 6.2.3;
                      </li>
                      <li>
                        damages incurred due to failure to observe the limits of permitted use of the Database under Section 4.
                      </li>
                    </ol>
                  </li>
                  <li>
                    If the User denies having authorized (given consent) the performance of a transaction, the use of the Account and password shall be deemed sufficient evidence for that the User has given consent to the transaction or that the User has acted fraudulently, or has intentionally or negligently failed to fulfil the duties prescribed in Sections 3.1 and 5.1.
                  </li>
                  <li>
                    Kvotum’s liability:
                    <ol>
                      <li>
                        If as result of Kvotum’s activity the User suffers losses, Kvotum shall reimburse all direct material losses inflicted upon the User. The extent of damages to be compensated by Kvotum is limited to EUR 2500. 
                      </li>
                      <li>
                        Kvotum is not liable for the accuracy and completeness of information provided by the Platform, the Borrower and/or the Original Lender, whether or not such information is made available in the Database. 
                      </li>
                      <li>
                        Kvotum shall not assume any liability towards the User for any losses that the User has or might suffer as a result of using the Account or acquiring Claims and in addition to the liability exclusions stipulated in Section 8.12, including:
                        <ol>
                          <li>
                            if the User has failed to observe provisions of the Terms of Use;
                          </li>
                          <li>
                            as a result of a conduct of third parties until the moment the Account has been blocked pursuant to the procedure established in Section 5.2;
                          </li>
                          <li>
                            due to interrupted communications and other interruptions or obstacles that are not dependent on Kvotum;
                          </li>
                          <li>
                            if pursuant to the Terms of Use the Account has been blocked;
                          </li>
                          <li>
                            due to a conduct of the Borrower and/or due to the Borrower infringing the Loan Agreement;
                          </li>
                          <li>
                            due to availability issues described in Section 12 or any issues arising from third-party content as described in Section 13;
                          </li>
                          <li>
                            due to failure to perform or delayed performance of the User’s obligations under the Assignment Agreement.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    The Parties shall not be liable for failure to fulfil their obligations if the non-fulfilment has occurred due to reasons that are independent of the Parties will (force majeure), which the Parties could not have predicted or affected, including power outages, adopted laws and regulations, strikes, military activities, natural disasters, or other circumstances, which the Parties could not have prevented or predicted. The Party suffering from the force majeure circumstances shall have the right to refer to them only and solely in case if it has taken all steps that depend on it in order to fulfil the obligations under the Terms of Use. It is considered that the official document (reference, decision etc.) provided by the competent state or government establishment is a full argument of existence of the force majeure circumstances. Once the force majeure circumstances have been prevented, the Parties must immediately resume the performance of their obligations under the Terms of Use.
                  </li>
                  <li>
                    As the Parties use means of communications during the performance of the Terms of Use, Kvotum shall not be held responsible for any loss incurred due to interrupted service of mail, electronic or other means of communications, as well as technologies ensuring the respective Kvotum services, including, but not limited to, interrupted services of means of communication, interruptions in the functioning of the Website, electronic data exchange and payment system of credit institutions, payment institutions or electronic money institutions (including online banking).
                  </li>
                </ol>
              </li>
              <li>
                <b>AVAILABILITY</b>
                <ol>
                  <li>
                    Kvotum will endeavour to ensure that the Website, the Database, the Account and its functions are always available, however Kvotum cannot give any assurances of all time availability. The Website and the Database are provided “as is” and “as available”. Kvotum is not obliged to ensure that the Website, the Database and the Account can be accessed at all times without any interruptions or faults, and does not assume any liability for this.
                  </li>
                  <li>
                    It may be that the Website, the Database and/or the Account are not available in the following cases, for example: 
                    <ol>
                      <li>
                        if the defect or fault in the Account or the Database provided via the Website results from the User having amended or modified the Website or in any way used the Website outside the scope of its normal and intended access and its intended usage;
                      </li>
                      <li>
                        if the defect or fault in the Website or the Account results from an issue with the User’s device;
                      </li>
                      <li>
                        in case of technical malfunctions.
                      </li>
                    </ol>
                  </li>
                  <li>
                    The User may access and use the Website, the Database and the Account through a mobile device and computer. As the Website is provided over the Internet and mobile networks, the quality and availability of the Website may be affected by factors outside Kvotum’s reasonable control. Not all of the functions may be available on the mobile device. The User is solely responsible for any prerequisite software and hardware requirements and for any data charges and fees associated with accessing and using the Website through a mobile device.
                  </li>
                </ol>
              </li>
              <li>
                <b>THIRD-PARTY CONTENT</b>
                <ol>
                  <li>
                    Any content provided through the Website is intended to be used and must be used for informational purposes only. It is important that the User does its own analysis before acquiring any Claim based on the User’s own personal circumstances. The User should take independent financial advice from a professional in connection with, or independently research and verify, any information that is provided on the Website by Kvotum or third parties and that the User wishes to rely upon, whether for the purpose of making a decision regarding acquiring a Claim or otherwise. Any content, data, information, or publications made available through the Website are furnished on an as-is basis for the User’s convenience and information. Any opinions, advice, statements, services, offers, or other information made available by third parties are those of the respective author(s) or publisher(s), and not of Kvotum. Such information should not be interpreted as approval by Kvotum of those content or information the User may obtain from them. Kvotum has no control over the content or information of these resources. Kvotum disclaims any warranty or representation, either express or implied, that the information in such publications is accurate or complete.
                  </li>
                  <li>
                    Use of certain links on the Website will direct the User to third party feeds, software, websites or mobile applications. The content of such links is not under the control of Kvotum, and Kvotum is not responsible for the contents of such links. If the User decides to access any third party information linked on the Website, the User does so entirely on its own risk. 
                  </li>
                </ol>
              </li>
              <li>
                <b>USER’S PERSONAL DATA</b>
                <ol>
                  <li>
                    For registering the Account and using its functions, the User is required to provide some personal data. The User acknowledges that Kvotum will collect and use certain personal data as described in Kvotum’s Privacy Notice. Further information about Kvotum collecting, using, disclosing and protecting the User’s personal data, can be obtained from the Privacy Notice at https://kvotum.com/privacy.html. 
                  </li>
                </ol>
              </li>
              <li>
                <b>ACTIONS IN CASE OF INSOLVENCY OF KVOTUM</b>
                <ol>
                  <li>
                    In case of Kvotum’s insolvency, no new Assignment Agreements shall be concluded on the Database. 
                  </li>
                  <li>
                    In case of Kvotum's insolvency, Kvotum provides the User with full information on transactions concluded via the Database.
                  </li>
                  <li>
                    Kvotum’s insolvency does not affect the contractual relations between the User, the Original Lender, the Borrower and the Platform. 
                  </li>
                </ol>
              </li>
              <li>
                <b>INTELLECTUAL PROPERTY RIGHTS</b>
                <ol>
                  <li>
                    The Website, the Database, trademarks and other intellectual property objects displayed, distributed, or otherwise made available via the Website, is the exclusive property of Kvotum (its successors, assigns, licensors, and/or suppliers) or the Platform. Unless specifically provided in the Terms of Use or if the User has agreed otherwise in writing with Kvotum, nothing in the Terms of Use gives the User right to use the Website and its content, any displayed trade-marks or other intellectual property of Kvotum or the Platform. 
                  </li>
                  <li>
                    Kvotum grants the User a personal, non-exclusive, non-transferable, non-sublicensable, revocable license to use the Website for personal, non-commercial use, as described in these Terms of Use, for the period the User accesses its Account and the functions related to it, if relevant. Any intellectual property objects (without limitation, all information, data, products, materials, services, software and tools, APIs, design elements, text, images, photographs, illustrations, audio and video contents, artwork and graphics contained therein or otherwise made available to you in connection therewith) provided through or used to operate the Website is licensed, not sold, to the User by Kvotum. The User acknowledges that the User has no right to access the source-code of the provided functions. Kvotum may inform you, by notice within the Account or otherwise, that the Website contains intellectual property governed by the licence of a third party and you agree to abide by the relevant terms. 
                  </li>
                  <li>
                    Unless the User has been permitted in writing to do so in a separate agreement with Kvotum, the User has no right to rent, lease, lend, sell, redistribute, sublicense, copy, reverse, engineer, decompile, disassemble, translate, modify, distribute copies of, make available, adapt, or create derivative works based on the Website or its related intellectual property.
                  </li>
                  <li>
                    For all User Content, the User grants Kvotum free of charge a transferable, sublicensable, non-exclusive, irrevocable, worldwide right of use and exploitation and for the maximum term permitted under applicable law and which is unlimited in terms of content, to use these User Content for any purpose including but not limited to the purposes of:
                    <ol>
                      <li>
                        providing the Website, the Database, the Account and its functions;
                      </li>
                      <li>
                        conducting research, developing new functions and services;
                      </li>
                      <li>
                        statistics, analytics and insights; 
                      </li>
                      <li>
                        improvement and further development of the Website and the Database; and
                      </li>
                      <li>
                        other, including commercial use.
                      </li>
                    </ol>
                  </li>
                  <li>
                    The Right of Use and Exploitation covers in particular, but not exclusively, the right to amend, edit and translate, as well as to store, reproduce, disseminate, make accessible to the public, send, disclose publicly and non-publicly and otherwise make available the User Content. By entering or making available User Content via the Website, the User guarantees that the User Content is in accordance with these terms herein, do not violate the rights of third parties and that the User is entitled to grant these Rights of Use and Exploitation. The User shall indemnify Kvotum against all claims brought by third parties against Kvotum in connection with the exercise of these Rights of Use and Exploitation. 
                  </li>
                </ol>
              </li>
              <li>
                <b>TERM AND TERMINATION</b>
                <ol>
                  <li>
                    The User Agreement will remain in effect until terminated by either the User or Kvotum. 
                  </li>
                  <li>
                    In case Kvotum detects material breach as determined in Section 4.2, Kvotum is entitled to terminate the User Agreement immediately, without prior notice. 
                  </li>
                  <li>
                    Kvotum has the right to unilaterally terminate the User Agreement at any time, by sending at least 30 (thirty) day prior notice to the User’s e-mail. As of the moment of sending the notice, the User is forbidden to conclude new Assignment Agreements and acquire new Claims. During this period, Kvotum may restrict the access to the Account. 
                  </li>
                  <li>
                    At any time during the validity of the User Agreement, the User is entitled to request that the Account is deleted and the User Agreement is terminated by sending a relevant notification from the User’s e-mail address to Kvotum or by filling in the relevant notification on the Account and confirming it according to the procedures stipulated on the Website. Kvotum will reply to that request for confirming the fact of termination of the User Agreement. If at the time of termination of the Agreement there are User’s outstanding obligations towards Kvotum, Kvotum shall prohibit the User from using the Database, including to conclude new transactions. 
                  </li>
                  <li>
                    Within 90 (ninety) days as of the termination of the User Agreement, the User has the right to submit a request to support@kvotum.com to receive the Assignment Agreements and the initial assignment documents about Kvotum acquiring the Claims the User has acquired through the Database.  The User acknowledges, that Kvotum may request for identity verification prior to performing the request. 
                  </li>
                </ol>
              </li>
              <li>
                <b>CHANGES TO THE TERMS OF USE</b>
                <ol>
                  <li>
                    Information about amendments in the Terms of Use is notified to the User at least 14 (fourteen) calendar days in advance prior to entering into force via the Website and / or the contact details provided on the Account. 
                  </li>
                  <li>
                    Kvotum is entitled to unilaterally amend the Price List at any time, including determining commission fees, as well as at the unilateral discretion of Kvotum to grant discounts from fees indicated on the Price List. Information about the amendments in the Price List is provided to the User at least 30 (thirty) calendar days prior to entering into force via the Website and / or the contact details provided on the Account. 
                  </li>
                  <li>
                    The amendments will become effective and binding the day after the advance notice period ends. In case the User does not agree with the amendments, the User has the right to terminate the User Agreement as described in Section 17.
                  </li>
                  <li>
                    Kvotum has the right to change the Terms of Use with the following types of changes without providing the User with a prior notice:
                    <ol>
                      <li>
                        if the change to the Terms of Use is only advantageous for the User;
                      </li>
                      <li>
                        if the change relates solely to new services, functionalities or service components, and does not result in any change to the existing contractual relationship with the User;
                      </li>
                      <li>
                        if the change is necessary to harmonise the Terms of Use with the applicable statutory requirements, in particular in the event of a change in the applicable legal situation, and if the change does not have any material detrimental effects on the User; or
                      </li>
                      <li>
                        if Kvotum is obliged to implement the change in order to comply with a court judgment that is binding for Kvotum or with a binding decision by an authority, and if the change does not have any material detrimental effects on the User.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <b>DISPUTES SETTLEMENT AND APPLICABLE LAW</b>
                <ol>
                  <li>
                    These Terms of Use and the resulting User Agreement and Assignment Agreement and any contractual or non-contractual disputes arising out of or in connection with the use of the Website will be governed by and in accordance with the laws of the Republic of Estonia and will be settled in Harju County Court (Estonia).  
                  </li>
                  <li>
                    The European Commission has set up an online dispute resolution platform, which can be found at http://ec.europa.eu/consumers/odr.
                  </li>
                </ol>
              </li>
              <li>
                <b>OTHER CONDITIONS</b>
                <ol>
                  <li>
                    All transactions concluded via the Website shall be made in Euro.
                  </li>
                  <li>
                    If the representation of the numbers in words in the text of the Agreement differs from the representation in numbers, the representation of the numbers in words shall prevail.
                  </li>
                  <li>
                    Situations not described in the Terms of Use shall be settled in accordance with information stipulated on the Website valid at the moment of appearance of such situation.
                  </li>
                  <li>
                    All notifications of the Parties must be sent to the other Party through the Account, by e-mail, or registered mail. Notifications sent to the User’s e-mail address shall be deemed received within 24 (twenty-four) hours after the dispatch.
                  </li>
                  <li>
                    The Parties hereto confirm that they have requested that the Terms of Use, including the resulting User Agreement and Assignment Agreement and all relevant documents are drafted and concluded in English. Any translation hereof (if any) has been provided for information purposes only and does not have any legal value nor create any contractual relationship between the Parties.  
                  </li>
                  <li>
                    Kvotum may at its sole discretion and at any time and without the consent of the User or any party to the Loan Agreement or the Assignment Agreement:
                    <ol>
                      <li>
                        assign any right and/or delegate its obligations under the Terms of Use to any Affiliated Company, and/or
                      </li>
                      <li>
                        delegate to any Affiliated Company the performance of any Website related services (including receipt of and making payments to the User).
                      </li>
                    </ol>
                  </li>
                  <li>
                    The authorizations, instructions, consents, permissions or powers of attorney given to Kvotum under the Terms of Use shall be deemed given to Affiliated Company as well, where applicable.
                  </li>
                  <li>
                    The Terms of Use are binding on the User until the Account is deleted and all commitments of the Borrowers originating from the Loan Agreements and related Claims acquired by the User, have been fully met.  
                  </li>
                  <li>
                    In case of any questions and requests, the User can contact Kvotum by sending an e-mail to the support service of Kvotum at e-mail address: hello@kvotum.com.
                  </li>
                </ol>
              </li>
            </ol>
            <p>
              Version : 1<br />
              Date of the Version: 01.08.2021<br />
              Effective as of: 02.08.2021<br />
            </p>
          </div>
        </div>
      </div>
    </section>    
      </main>
      <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
        <FooterComponent />
      </footer>
    </>
  )
}

export default PrivacyPage